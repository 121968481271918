<template>
  <div class="tinymce-editor">
    <editor v-model="myValue"
            :init="init"
            :disabled="disabled"
            @input="inp()"
            @onClick="onClick">
    </editor>
  </div>
</template>

<script>
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver'
import 'tinymce/icons/default/icons'
import 'tinymce/plugins/image'// 插入上传图片插件
import 'tinymce/plugins/media'// 插入视频插件
import 'tinymce/plugins/table'// 插入表格插件
import 'tinymce/plugins/lists'// 列表插件
import 'tinymce/plugins/wordcount'// 字数统计插件,
import api from '../api/xiaochengxu';
export default {
  name: "teditor",
  components: {
    Editor
  },
  props: {
    tinymceHtml: {
      type: String,
      default: ''
    },
    // 基本路径，默认为空根目录，如果你的项目发布后的地址为目录形式，
    // 即abc.com/tinymce，baseUrl需要配置成tinymce，不然发布后资源会找不到
    baseUrl: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plugins: {
      type: [String, Array],
      default: 'lists image media table wordcount'
    },
    toolbar: {
      type: [String, Array],
      default: 'undo redo |  formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media table | removeformat'
    }
  },
  data: function(){
    return {
      init: {
        language_url: `${this.baseUrl}/tinymce/langs/zh_CN.js`,
        language: 'zh_CN',
        skin_url: `${this.baseUrl}/tinymce/skins/ui/oxide`,
        content_css: `${this.baseUrl}/tinymce/skins/content/default/content.css`,
        // skin_url: `${this.baseUrl}/tinymce/skins/ui/oxide-dark`, // 暗色系
        // content_css: `${this.baseUrl}/tinymce/skins/content/dark/content.css`, // 暗色系
        height: 700,
        plugins: this.plugins,
        toolbar: this.toolbar,
        branding: false,
        menubar: false,
        // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        images_upload_handler: (blobInfo, success, failure) => {
          // const img = 'data:image/jpeg;base64,' + blobInfo.base64()
          // success(img)
          let formdata = new FormData();
          formdata.append('file', blobInfo.blob());
          this.$http.post(api.upload, formdata).then(response => {
            if(response.data.success) {
              success(response.data.path);
            } else {
              failure(response.data.err);
            }
          })
        }
      },
      myValue: this.tinymceHtml
    }
  },
  watch: {
    // 我们需要定义一个监听器
    tinymceHtml: {
      deep: true,  // 深度监听  可加可不加当你传递的是一个对象时，我们需要将深度监听加上
      handler(newVal) {
        // 我在这里直接让v-model的值等于this.tinymceHtml父组件传递过来的值了，
        // 当然你等于newVal也可以 这个时候我们就实现了sunHtml双绑值同步于父组件传递的tinymceHtml值
        this.myValue = newVal
      }
    }
  },
  methods: {
    onClick (e, editor) {
      console.log(e)
      console.log(editor)
    },
    inp() {
      this.$emit('inp', this.myValue)
    },
    clear () {
      this.$refs.editor.clear()
    }
  },
  mounted () {
    tinymce.init({})
  },
}
</script>

<style scoped>

</style>
